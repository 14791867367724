<script>
export default {
  props: ["id"],
  data() {
    return {
      nfe: {
        mod: "",
        serie: "",
        nDoc: "",
        dEmi: "",
        vBC: "",
        vICMS: "",
        vBCST: "",
        vST: "",
        vProd: "",
        vNF: "",
        nCFOP: "",
      },
      carga: {
        id: null,
        cUnid: "",
        tpMed: "",
        qCarga: "",
      },
      tab: null,
      headerNfes: [
        { text: "Modelo", value: "mod", sortable: false },
        { text: "Série", value: "serie", sortable: false },
        { text: "Número", value: "nDoc", sortable: false },
        { text: "Emissão", value: "dEmi", sortable: false },
        { text: "Base ICMS", value: "vBC", sortable: false },
        { text: "Total ICMS", value: "vICMS", sortable: false },
        { text: "Base ICMS ST", value: "vBCST", sortable: false },
        { text: "Total ICMS ST", value: "vST", sortable: false },
        { text: "Total Produtos", value: "vProd", sortable: false },
        { text: "Total NF", value: "vNF", sortable: false },
        { text: "CFOP", value: "nCFOP", sortable: false },
        { text: "Ações", align: "right", value: "action", sortable: false },
      ],
      headerCargas: [
        { text: "Unidade de Medida", value: "cUnid", sortable: false },
        { text: "Tipo de Unidade", value: "tpMed", sortable: false },
        { text: "Quantidade", value: "qCarga", sortable: false },
        { text: "Ações", align: "right", value: "action", sortable: false },
      ],
      formas_de_pagamento: [
        { id: 0, value: "Pago" },
        { id: 1, value: "A Pagar" },
        { id: 2, value: "Outros" },
      ],
      naturezas_op: ["Conhecimento de Transporte"],
      tipos_cte: [
        { id: 0, value: "CT-e Normal" },
        { id: 1, value: "CT-e de Complemento de Valores" },
        { id: 2, value: "CT-e de Anulação" },
        { id: 3, value: "CT-e Substituto" },
      ],
      tomadores: [
        { id: 0, value: "Remetente" },
        { id: 1, value: "Expedidor" },
        { id: 2, value: "Recebedor" },
        { id: 3, value: "Destinatário" },
        { id: 4, value: "Outros" },
      ],
      tipos_servicos: [
        { id: 0, value: "Normal" },
        { id: 1, value: "Subcontratação" },
        { id: 2, value: "Redespacho" },
        { id: 3, value: "Redespacho Intermediário" },
        { id: 4, value: "Serviço Vinculado a Multimodal" },
      ],
      modelos_nfe: [
        { id: "01", value: "NF Modelo 01/1A e Avulsa" },
        { id: "04", value: "NF de Produtor" },
      ],
      unidadeMedidas: [
        { id: "00", value: "M3" },
        { id: "01", value: "KG" },
        { id: "02", value: "TON" },
        { id: "03", value: "UNIDADE" },
        { id: "04", value: "LITROS" },
        { id: "05", value: "MMBTU" },
      ],
      tiposMedidas: [
        "PESO BRUTO",
        "PESO DECLARADO",
        "PESO CUBADO",
        "PESO AFORADO",
        "PESO AFERIDO",
        "PESO BASE DE CÁLCULO",
        "PESO BASE DE CÁLCULO",
        "LITRAGEM",
        "CAIXAS",
        "OUTROS",
      ],
      nfes: [],
      cargas: [],
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      cod_barras: "",
      agencia: [],
      onibus: [],
      motoristas: [],
      trechos: [],
      clientes: [],
      empresas: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      cnpjRules: [(v) => !!v || "Este campo é requerido"],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
    };
  },
  created() {
    this.loadEmpresas();
    this.loadAgency();
    this.loadMotoristas();
    this.loadOnibus();
    this.loadClients();
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/encomendas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    } else {
      this.gerarCodigodeBarras();
    }
  },
  methods: {
    getCodeUNCarga(cUnid) {
      const Template = this.unidadeMedidas.find((un) => un.id == cUnid);
      if (Template) return `${Template.value}`;
      return false;
    },
    deleteNfe(ndoc) {
      const indexNfe = this.nfes.findIndex((nfe) => nfe.nDoc == ndoc);
      this.nfes.splice(indexNfe, 1);
    },
    deleteCarga(id) {
      const indexCarga = this.cargas.findIndex((carga) => carga.id == id);
      this.cargas.splice(indexCarga, 1);
    },
    insertNFE() {
      const nfeExists = this.nfes.find((nfe) => nfe.nDoc == this.nfe.nDoc);
      if (nfeExists) {
        this.message.text = "Nota fiscal já adicionada!";
        this.message.snacshow = true;
        this.message.type = "warning";
        return;
      }
      if (
        this.nfe.mod == "" ||
        this.nfe.serie == "" ||
        this.nfe.nCFOP == "" ||
        this.nfe.nDoc == "" ||
        this.nfe.dEmi == "" ||
        this.nfe.vICMS == "" ||
        this.nfe.vST == "" ||
        this.nfe.vProd == ""
      ) {
        this.message.text = "Preencha todos os campos para continuar!";
        this.message.snacshow = true;
        this.message.type = "warning";
        return;
      }

      this.nfes.push(this.nfe);

      this.nfe = {
        mod: "",
        serie: "",
        nDoc: "",
        dEmi: "",
        vBC: "",
        vICMS: "",
        vBCST: "",
        vST: "",
        vProd: "",
        vNF: "",
        nCFOP: "",
      };
    },
    insertCargas() {
      if (
        this.carga.cUnid == "" ||
        this.carga.tpMed == "" ||
        this.carga.qCarga == ""
      ) {
        this.message.text = "Preencha todos os campos para continuar!";
        this.message.snacshow = true;
        this.message.type = "warning";
        return;
      }
      this.carga.id = Math.random();
      this.cargas.push(this.carga);

      this.carga = {
        id: null,
        cUnid: "",
        tpMed: "",
        qCarga: "",
      };
    },
    gerarCodigodeBarras() {
      this.cod_barras = Math.floor(Math.random() * 99999999999999);
    },
    calculateValue() {
      if (this.dados.quant_volumes && this.dados.valor) {
        this.$http
          .post(`/encomendas-calcular`, {
            valor: this.dados.valor,
            quantidade: this.dados.quant_volumes,
          })
          .then((r) => {
            this.dados.valor_total = r.data[0];
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
          });
      }
    },
    loadClients() {
      this.$http
        .get(`/encomendas/clientes`)
        .then((r) => {
          this.clientes = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadEmpresas() {
      this.$http
        .get(`/empresas`)
        .then((r) => {
          this.empresas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadOnibus() {
      this.$http
        .get(`/bus`)
        .then((r) => {
          this.onibus = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadMotoristas() {
      this.$http
        .get(`/motoristas`)
        .then((r) => {
          this.motoristas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadAgency() {
      this.$http
        .get(`/pdv/agencias/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.agencia = r.data[0];
          this.dados.id_localidade_origem = this.agencia.id_localidade;
          this.loadTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loadTrechoInicial() {
      this.$http
        .get(`/pdv/localidades/${this.agencia.id_localidade}`)
        .then((r) => {
          this.trechosDestino = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "errors";
        });
    },
    loadTrechos() {
      this.$http
        .get(`/localidades`)
        .then((r) => {
          this.trechos = r.data;
          this.loadTrechoInicial();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.dados.id_agencia = this.agencia.id;
      this.dados.remetente = this.dados.remetente.toUpperCase();
      this.dados.destinatario = this.dados.destinatario.toUpperCase();
      this.dados.natureza = this.dados.natureza.toUpperCase();
      this.$http
        .post("/encomendas/create", {
          dados: this.dados,
          id_operador: this.$store.getters.getOperador,
          id_agencia: this.$store.getters.getOpAgency,
        })
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.success) {
            this.message.text = r.data.success;
            this.message.snacshow = true;
            this.message.type = "success";

            setTimeout(() => {
              // um número no formato internacional (ativo no WhatsApp)
              let number = "55" + r.data.telefone;
              // o texto ou algo vindo de um <textarea> ou <input> por exemplo
              let msg =
                "Olá, " +
                r.data.nome +
                ", muito obrigado por utilizar os nossos serviços, Acompanhe sua encomenda pelo código: " +
                r.data.codigo +
                " att: TRANSBRAZ";

              // montar o link (apenas texto) (web)
              let target = `https://web.whatsapp.com/send?text=${encodeURIComponent(
                msg
              )}`;

              // montar o link (número e texto) (web)
              target = `https://web.whatsapp.com/send?phone=${encodeURIComponent(
                number
              )}&text=${encodeURIComponent(msg)}`;

              let a = document.createElement("a");
              a.target = "_blank";
              a.href = target; // o link URL do api.whatsapp.com
              a.click(); // simular o evento de "click"
              this.$router.push("/encomendas/gerenciar");
            }, 1500);
          } else {
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "error";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
  },
};
</script>
